* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  overflow: hidden;
}

.experience {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 0;
}

.joystick {
  position: fixed;
  bottom: 0;
  z-index: 10000;
  width: 10rem;
  height: 10rem;
}

.button {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 10000;
  width: 5rem;
  height: 5rem;
  font-size: 3rem;
  text-align: center;
  color: #fff;
  opacity: 50%;
}
